<script setup lang="ts">
const { industries } = useHome()

const industryOrder: string[] = [
  'Logistics',
  'EdTech',
  'Food & Beverages',
  'Industries where we bring value',
  'FinTech',
  'MedTech',
  'Warehouse',
  'Manufacturing',
]

const industryClasses = {
  Logistics:
    'hover-effect group col-span-4 flex flex-col justify-between rounded-lg border-4 border-white bg-gradient-to-b from-cyan-200 from-10% via-yellow-50 to-white to-90% p-4 shadow md:col-span-4 lg:col-span-3 lg:row-span-2 lg:border-8 lg:p-6',
  EdTech: 'col-span-4 md:col-span-8 lg:col-span-6',
  'Food & Beverages': 'col-span-4 md:col-span-4 lg:col-span-3 lg:row-span-2',
  FinTech: 'col-span-4 md:col-span-4 lg:col-span-3 lg:row-span-2',
  MedTech: 'col-span-4 md:col-span-4 lg:col-span-3',
  Warehouse: 'col-span-4 md:col-span-4 lg:col-span-4',
  Manufacturing: 'col-span-4 md:col-span-8 lg:col-span-5',
  Industries: 'col-span-4 md:col-span-8 lg:col-span-6 lg:row-span-2',
  'Industries where we bring value':
    'group col-span-4 flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border bg-gradient-to-b from-teal-200 via-green-100 to-teal-100 p-4 text-center shadow md:col-span-8 lg:col-span-6 lg:row-span-2 lg:p-6',
  default: 'col-span-4',
}

const flexDirectionMap = {
  EdTech: 'flex-row',
  Warehouse: 'flex-row',
  Manufacturing: 'flex-row',
  default: 'flex-col',
}

function getIndustryLink(title: string): string {
  const routes: Record<string, string> = {
    Logistics: '/logistics',
    EdTech: '/projects/edtech',
    'Food & Beverages': '/projects/food-and-beverages',
    FinTech: '/projects/fintech',
    MedTech: '/projects/medtech',
    Warehouse: '/projects/warehouse',
    Manufacturing: '/projects/medtech',
  }
  return routes[title] || '/projects'
}

function getCardClasses(title: string): string {
  return industryClasses[title]! || industryClasses.default
}

function getFlexDirection(title: string): string {
  return flexDirectionMap[title] || flexDirectionMap.default
}

const sortedIndustryCards = computed(() => {
  if (!industries?.value?.industry_cards) return []
  return industries.value.industry_cards.slice().sort((a, b) => {
    const indexA = industryOrder.indexOf(a.title!)
    const indexB = industryOrder.indexOf(b.title!)
    return indexA - indexB
  })
})
</script>

<template v-if="industries">
  <Container class="screen-content p-2 lg:p-4 lg:px-2 lg:py-8">
    <div class="grid grid-cols-4 gap-3 md:grid-cols-8 lg:grid-cols-12 lg:gap-6">
      <IndustryCard
        v-for="industry in sortedIndustryCards"
        :key="industry.title"
        :title="industry.title!"
        :description="industry.description!"
        :icon="industry.icon?.url"
        :flex-direction="getFlexDirection(industry.title!)"
        :link="getIndustryLink(industry.title!)"
        :main="industry.main!"
        :class="getCardClasses(industry.title!)"
      />
    </div>
  </Container>
</template>

<style scoped>
.hover-effect {
  transition: all 0.3s ease-in-out;
}

.hover-effect:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
</style>
