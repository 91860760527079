<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
  },
  link: {
    type: String,
    required: true,
  },
  flexDirection: {
    type: String,
    required: true,
  },
  main: {
    type: Boolean,
    required: true,
  },
})

const isLogistics = props.title === 'Logistics'
</script>

<template>
  <NuxtLink
    v-if="!props.main"
    :to="props.link"
    :class="[
      !isLogistics && 'hover-effect group cursor-pointer rounded-lg border bg-white shadow',
      !isLogistics && '',
      'mb-8 flex h-full justify-between gap-4 rounded-lg border p-4 transition-all lg:p-2',
    ]"
  >
    <div
      :class="[props.flexDirection, !isLogistics && 'hover:bg-main-100 border']"
      class="hover:border-main-500 flex h-full justify-between gap-4 rounded-lg p-4 transition-all lg:p-6"
    >
      <div
        v-if="props.icon"
        class="border-main-100 flex h-16 w-16 items-center justify-center rounded-full border-4 lg:h-20 lg:w-20 lg:border-8"
      >
        <div
          class="bg-main-200 flex h-12 w-12 items-center justify-center rounded-full p-3 text-white lg:h-16 lg:w-16 lg:p-4"
        >
          <img :src="props.icon" class="h-full w-full object-contain" alt="Icon" />
          <Icon class="h-6 w-6 lg:h-8 lg:w-8" name="material-symbols:payments-outline" />
        </div>
      </div>
      <div class="text-left">
        <Heading as="h3" class="mb-2 flex items-center gap-2 text-base font-medium lg:text-lg">
          {{ props.title }}
          <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
        </Heading>
        <Paragraph size="small">
          {{ props.description }}
        </Paragraph>
      </div>
    </div>
  </NuxtLink>

  <NuxtLink
    v-else
    to="/projects"
    class="group col-span-4 flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border bg-gradient-to-b from-teal-200 via-green-100 to-teal-100 p-4 text-center shadow md:col-span-8 lg:col-span-6 lg:row-span-2 lg:p-6"
  >
    <Heading
      class="flex items-center gap-2 bg-gradient-to-r from-teal-800 via-teal-700 to-teal-950 bg-clip-text text-transparent"
    >
      {{ $t('home.industries.title') }}
      <Icon class="invisible rotate-90 group-hover:visible" name="material-symbols:arrow-insert-rounded" />
    </Heading>
    <Paragraph size="medium" class="mb-4 mt-4 w-full lg:w-2/3">{{ $t('home.industries.description') }}</Paragraph>
    <Action intent="main">{{ $t('home.industries.actionLabel') }}</Action>
  </NuxtLink>
</template>

<style scoped>
.group:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
</style>
